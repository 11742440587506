 

const state = {
	bgSetting: true,
	userdata:null,
	company_profile: null,
	online: 0,
	backgroundImage: null
}

export default state