<template>
  <div id="app"> 
       <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut">
      <router-view/> 
    </transition>
  </div>
</template>

<script>
  import cookie from 'js-cookie';
  import * as moment from 'moment'
  export default {
    name:'router',
    mounted() {
      this.getComPro()
      this.visit()
      this.getBackgroundSetting()
    },
    sockets: {
      connect: function () {
        //console.log('socket connected')
      },
      userCount: function(data) {
        this.$store.commit("SOCKET_ONLINE", data.userCount)
        //console.log('user count', data);
      }
    },
    methods: {
      getBackgroundSetting() {
        this.axios.get('setting/background_image')
        .then( response => {
          if (response.data.result.setting.value) {
            this.$store.commit('SET_BACKGROUND_IMAGE', response.data.result.setting.value)
          }
        })
      },
      getComPro() {
        this.axios.get('company-profile')
          .then( response => {
            this.$store.commit("SET_COMPRO", response.data.result.company_profile);
          })
      },
      visit() {
        let today = moment().format('YYYY-MM-DD')

        if (cookie.getJSON('visit') !== undefined) {

          let histories = cookie.getJSON('visit')

          let isHadVisitToday = histories.find( date => date == today)

          if (!isHadVisitToday) {
             this.axios.post('visitor')
              .then( () => {
                histories.push(today)
                cookie.set('visit', histories)
              })
          }

        } else {
          this.axios.post('visitor')
          .then( () => {
            cookie.set('visit', [today])
          })
        }
      },
      addSessionToken() {
        this.axios.get('visitor/online')
          .then( response => {
            cookie.set('session_online', response.data.result.online.session)
          })
      },
      async removeToken() {
        const token = cookie.get('session_online')
        if (token) {
          cookie.remove('session_online')
          await this.axios.delete('visitor/offline/' + token)
        }
      }
    }
  }
</script>