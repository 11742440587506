import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './registerServiceWorker'
import BootstrapVue from 'bootstrap-vue' 
import vSelect from 'vue-select'
import { LoaderPlugin } from 'vue-google-login'
import VeeValidate from 'vee-validate'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.component('v-select', vSelect)
 
import Meta from "vue-meta";
Vue.use(Meta);
import cookie from 'js-cookie'
Vue.use(cookie);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    libraries: 'places',
  },
  installComponents: true
})

Vue.use(LoaderPlugin, {
  client_id: '883629074374-38kqb0b9051qhi1l3bp2unujlsoqtr1h.apps.googleusercontent.com'
})
Vue.GoogleAuth.then(auth2 => {
  Vue.prototype.$authGoogle = auth2
  // console.log(auth2.isSignedIn.get())
})
// import bootstrap
Vue.use(BootstrapVue)
import 'bootstrap/dist/css/bootstrap.css' 
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css'
import '@/assets/scss/main.scss'
import 'slick-carousel/slick/slick.css'

// validator
// facebook
import VueFacebookPage from 'vue-facebook-page'
Vue.use(VueFacebookPage, '395619728293302')

Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
})
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, {
    moment,
})

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
  timeout: 180000, // 3 menit
  headers: {
    Authorization: 'Bearer ' + process.env.VUE_APP_TOKEN_API,
    'Content-Type': 'multipart/form-data',
    'X-timezone': 'Asia/Jakarta',
    'Accept': 'application/json'
  }
}))
// font
require('./assets/css/iconfont.css')
require('./assets/css/line-awesome-font-awesome.min.css')
// import tailwind
import '@/assets/css/tailwind.css'
 
import VueNumerals from 'vue-numerals';
Vue.use(VueNumerals);

if (cookie.getJSON('userdata') !== undefined) {
  let UserData = cookie.getJSON('userdata')
  store.commit('SET_LOGIN', UserData)
}

Vue.config.productionTip = false

Vue.filter('date_only', function(date) {
  return moment(date).format('DD MMMM YYYY')
})

Vue.filter('time_only', function (date) {
  return moment(date).format('HH:mm')
})

import stripHtml from "string-strip-html";
Vue.filter('sort_desc', function (string, length = 150) {
  let val = stripHtml(string)
  if (val.length > length) {
    return val.substr(0,length) + '...'
  } else {
    return val
  }
})

Vue.mixin({
  methods: {
    saveLastView(type, key) {
      if (cookie.getJSON(type) !== undefined) {
        let data = cookie.getJSON(type)
        let cek = data.find( item => item == key)
        if (!cek) {
          data.push(key)
          cookie.set(type, data); 
          return true
        }
        return false
      } else {
        let data = [key]
        cookie.set(type, data); 
        return true
      }
    }
  }
})

import VueSocketIO from 'vue-socket.io'
Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_BASE_URL_API,
  vuex: {
    store,
    mutationPrefix: "SOCKET_"
  },
  options: { path: "/visit/" } //Optional options
}))

new Vue({
  router,
  store,
  vSelect,
  render: h => h(App)
}).$mount('#app')
