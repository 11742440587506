/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Version: 1.1
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/


const getters = {
  backgroundImage(state) {
    if (state.backgroundImage) {
      return state.backgroundImage
    } else {
      return `@/assets/images/compro/misc/bg.jpg`
    }
  }
}

export default getters