 

const mutations = { 
	SET_BG(state, val) {
		state.bgSetting = val
	},
	SET_LOGIN (state, payload) {
		state.userdata = payload
	},
	SET_COMPRO(state, payload) {
		state.company_profile = payload
	},
	SOCKET_ONLINE(state, payload) {
		state.online = payload
	},
	SET_BACKGROUND_IMAGE(state, payload) {
		state.backgroundImage = payload
	}
}

export default mutations