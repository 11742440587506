import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Meta from "vue-meta";

Vue.use(VueRouter)
Vue.use(Meta);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
    path: '',
    component: () => import('@/views/main/main.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
      },  
      {
        path: '/layanan',
        name: 'layanan',
        component: () => import('../views/pages/layanan/layanan.vue')
      },
      {
        path: '/layanan/:slug',
        name: 'layanan-detail',
        component: () => import('../views/pages/layanan/detail.vue')
      },
      {
        path: '/Visi-misi',
        name: 'visi-misi',
        component: () => import('../views/pages/visi-misi/visi-misi.vue')
      }, 
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/pages/profile/profile.vue')
      },
      {
        path: '/pejabat',
        name: 'profile-pejabat',
        component: () => import('../views/pages/profile/pejabat.vue')
      },
      {
        path: 'perlu-kamu-tahu',
        name: 'perlu-kamu-tahu',
        component: () => import('../views/pages/info/info.vue')
      },
      {
        path: '/profile-detail',
        name: 'profile-detail',
        component: () => import('../views/pages/profile/profile-detail.vue')
      },
      {
        path: '/public-information',
        name: 'public-information',
        component: () => import('../views/pages/information/public.vue')
      },
      {
        path: '/important-information',
        name: 'important-information',
        component: () => import('../views/pages/information/important.vue')
      },
      {
        path: '/regulation-information',
        name: 'regulation-information',
        component: () => import('../views/pages/information/regulation.vue')
      },
      {
        path: '/information/:slug',
        name: 'detail-information',
        component: () => import('../views/pages/information/detail.vue')
      },
      {
        path: '/publikasi/:slug',
        name: 'publikasi-detail',
        component: () => import('../views/pages/publikasi/detail.vue')
      },
      {
        path: '/article',
        name: 'article',
        component: () => import('../views/pages/publikasi/article.vue')
      },
      {
        path: '/announcement',
        name: 'announcement',
        component: () => import('../views/pages/publikasi/announcement.vue')
      },
      {
        path: '/calendar',
        name: 'calendar',
        component: () => import('../views/pages/publikasi/calendar.vue')
      },
      {
        path: '/page/:slug',
        name: 'page-detail',
        component: () => import('../views/Page.vue')
      },
      {
        path: '/post/:slug',
        name: 'post-detail',
        component: () => import('../views/Post.vue')
      },
      {
        path: '/berita',
        name: 'berita',
        component: () => import('../views/pages/berita/berita.vue')
      },
      {
        path: '/berita/:slug',
        name: 'detail-berita',
        component: () => import('../views/pages/berita/detail.vue')
      },
      {
        path: '/gallery',
        name: 'gallery',
        component: () => import('../views/pages/gallery/gallery.vue')
      },
      {
        path: '/bantuan/istilahsaik',
        name: 'Istilah dalam SAIK',
        component: () => import('../views/pages/bantuan/istilahsaik.vue')
      },
      {
        path: '/bantuan/faq',
        name: 'FAQ',
        component: () => import('../views/pages/bantuan/faq.vue')
      },
      {
        path: '/404',
        name: 'page-not-found',
        component: () => import('../views/pages/404.vue')
      },
      {
        path: "*", 
        component: () => import('../views/pages/404.vue') 
      }
    ]},
  ]
}) 

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router
